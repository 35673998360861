import React, { FC, ReactNode, useMemo, useState } from 'react';
import FullView from '../FullView';
import SubscriptionModel from '../../../models/SubscriptionModel';
import Accordion from '../../Accordion/Accordion';
import AccordionTextItem from '../../Accordion/AccordionTextItem/AccordionTextItem';
import styled from './ConsultantFullView.module.css';
import FullViewFooter from '../FullViewFooter/FullViewFooter';
import { useTranslation } from 'react-i18next';
import ChangeTextLink from '../../ChangeTextLink/ChangeTextLink';
import AccordionListItem from '../../Accordion/AccordionListItem/AccordionListItem';
import { UNGROUPED_LAWLIST_NAME } from '../../../models/LawListModel';
import _ from 'lodash';
import useAuth from '../../../hooks/useAuth';

interface Props {
    data?: SubscriptionModel;
    open: boolean;
    disableAcknowledge: boolean;
    hideFooter: boolean;
    onClose: () => void;
    onAcknowledgeChange: () => void;
    onAcknowledgeNewChange: () => void;
}
const ConsultantFullView: FC<Props> = ({ data, open, disableAcknowledge, hideFooter, onClose, onAcknowledgeChange, onAcknowledgeNewChange }) => {
    const { t, i18n } = useTranslation();

    const { company } = useAuth();

    const [openAccordions, setOpenAccordions] = useState<number[]>([0]);

    const accordionItems = useMemo(() => {
        const items: { title: string; value: ReactNode }[] = [];

        const link = data ? <ChangeTextLink lawId={data.lawId} lawSources={data.lawSources} /> : null;
        items.push({
            title: t('columnChange'),
            value: <AccordionTextItem data={data?.changeText || ''} link={link} />,
        });

        items.push({
            title: t('columnText'),
            value: <AccordionTextItem data={data?.text || ''} />,
        });

        items.push({
            title: t('columnDescription'),
            value: <AccordionTextItem data={data?.description || ''} />,
        });

        if (company?.hasLawLists) {
            items.push({
                title: t('columnLawLists'),
                value: (
                    <AccordionListItem
                        data={
                            data?.lawLists?.map(lawList => {
                                if (lawList.lawListGroup && lawList.lawListGroup.name !== UNGROUPED_LAWLIST_NAME) {
                                    return lawList.lawListGroup.name + ': ' + lawList.name;
                                }
                                return lawList.name;
                            }) || []
                        }
                    />
                ),
            });
        }

        if (company?.hasKeyWords) {
            items.push({
                title: t('columnKeywords'),
                value: <AccordionListItem data={data?.keywordIds?.map(kwId => _.find(company.keyWords, kw => kw.id === kwId)?.text || '') || []} />,
            });
        }

        return items;
    }, [data, i18n.language]);

    const handleToggleAccordion = (index: number) => {
        setOpenAccordions(s => (s.includes(index) ? s.filter(i => i !== index) : [...s, index]));
    };

    return (
        <FullView
            lawSourceId={data?.lawId || -1}
            title={data?.name || ''}
            subId={data?.subId || ''}
            isCustomLaw={false}
            lawSource={data?.lawSources.length ? data.lawSources[0] : undefined}
            open={open}
            onClose={onClose}
        >
            <div className={styled.Content}>
                <Accordion id={data?.subscriptionId || -1} data={accordionItems} initiallyOpenIndicies={openAccordions} onToggle={handleToggleAccordion} />
            </div>
            {data?.acceptanceStatus && !hideFooter && (
                <FullViewFooter
                    data={data}
                    disabled={disableAcknowledge}
                    onDelegateChange={console.log}
                    onAcknowledgeChange={onAcknowledgeChange}
                    onAcknowledgeNewChange={onAcknowledgeNewChange}
                    onForceDelegation={console.log}
                />
            )}
        </FullView>
    );
};

export default ConsultantFullView;
