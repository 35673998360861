import React, { FC, useState, useEffect } from 'react';

import { htmlToText } from 'html-to-text';
import styled from './EditView.module.css';
import { ReactComponent as CloseIcon } from '../../assets/images/closeIcon.svg';
import Button from '../Button/Button';
import TextEditor from '../TextEditor/TextEditor';
import KeywordEditor from '../KeywordEditor/KeywordEditor';
import LawListEditor from '../LawListEditor/LawListEditor';
import { useTranslation } from 'react-i18next';

interface Props {
    title: string;
    value: any;
    field: string;
    maxLength?: number;
    consultantText?: string;
    onChange: (value: any) => void;
    onSubmit: () => void;
    onClose: () => void;
}

/**
 * Html strings without any visual characters should be empty. This function is piped on all html text updates sent to
 * the backend.
 *
 * No more invisible residue = searching for null in column filters works more reliably.
 */

export const trimHtmlString = (html: string): string =>
    htmlToText(html)
        .replace(/\n/g, '')
        .trim().length
        ? html
        : '';

const EditView: FC<Props> = ({ title, field, maxLength, consultantText, onSubmit, onClose, value, onChange, children }) => {
    const { t } = useTranslation();
    const [initValue, setInitValue] = useState(value);

    useEffect(() => setInitValue(value), [title]);

    const isChanged = initValue !== value;

    return (
        <div className={styled.EditView}>
            <div className={styled.CloseBar}>
                <CloseIcon onClick={onClose} />
            </div>
            <div className={styled.Content}>
                <div className={styled.Editor}>
                    {field === 'keywordIds' ? (
                        <KeywordEditor values={value} onChange={onChange} />
                    ) : field === 'lawList' ? (
                        <LawListEditor lawLists={value} onChange={onChange} />
                    ) : (
                        <TextEditor title={title} initialValue={value} onChange={onChange} maxLength={maxLength} consultantText={consultantText} />
                    )}
                </div>
                {children && <div className={styled.Children}>{children}</div>}
                <div className={styled.Footer}>
                    <Button variant="Outline" onClick={onClose}>
                        {t('buttonCancel')}
                    </Button>
                    <Button variant="Primary" onClick={onSubmit} disabled={!isChanged}>
                        {t('buttonSave')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EditView;
