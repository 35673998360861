import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { getAuthApi } from '../config/axios.config';
import ApiErrorModel from '../models/ApiErrorModel';
import { LawListContentModel } from './SubscriptionService';
import SubscriptionModel from '../models/SubscriptionModel';
import CustomLawModel from '../models/CustomLawModel';

const DetachedCopiesService = () => {
    const axiosInstance = getAuthApi();

    function getSubscriptionsForAddCopy(companyId: number): Promise<LawListContentModel> {
        return axiosInstance
            .get<LawListContentModel>(`/subscription/detachedcopy/subscriptionlist?companyId=${companyId}`)
            .then((response: AxiosResponse<LawListContentModel>) => {
                // Here we map empty string in text and customerText fields to null. We do this patch here instead of
                // in the backend because, the proper backend solution would be a migration script that changes all
                // empty strings to null.
                //
                // TODO: Migrate and update these fields in backend and remove this patch

                const isTextField = (key: string): boolean => !!key.match(/text|customerText*/);
                const updateObjVal = (val: any, key: string): any => (isTextField(key) && val === '' ? null : val);

                return {
                    ...response.data,
                    subscriptions: response.data.subscriptions.map(obj => _.mapValues(obj, updateObjVal) as SubscriptionModel),
                    customLaws: response.data.customLaws.map(obj => _.mapValues(obj, updateObjVal) as CustomLawModel),
                };
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getDetachedCopiesForRemove(companyId: number): Promise<LawListContentModel> {
        return axiosInstance
            .get<LawListContentModel>(`/subscription/detachedcopy?companyId=${companyId}`)
            .then((response: AxiosResponse<LawListContentModel>) => {
                // Here we map empty string in text and customerText fields to null. We do this patch here instead of
                // in the backend because, the proper backend solution would be a migration script that changes all
                // empty strings to null.
                //
                // TODO: Migrate and update these fields in backend and remove this patch

                const isTextField = (key: string): boolean => !!key.match(/text|customerText*/);
                const updateObjVal = (val: any, key: string): any => (isTextField(key) && val === '' ? null : val);

                return {
                    ...response.data,
                    subscriptions: response.data.subscriptions.map(obj => _.mapValues(obj, updateObjVal) as SubscriptionModel),
                    customLaws: response.data.customLaws.map(obj => _.mapValues(obj, updateObjVal) as CustomLawModel),
                };
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function createDetachedCopy(subscriptionId: number, lawListIds: number[]): Promise<SubscriptionModel[]> {
        return axiosInstance
            .post<SubscriptionModel[]>(`/subscription/detachedcopy?subscriptionId=${subscriptionId}&lawListIds=${lawListIds}`, undefined)
            .then((response: AxiosResponse<SubscriptionModel[]>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function deleteDetachedCopy(subscriptionIds: number[]): Promise<AxiosResponse<void>> {
        return axiosInstance.delete<void>(`/subscription/detachedcopy?subscriptionIds=${subscriptionIds}`).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    function hasDetachedCopies(companyId: number): Promise<boolean> {
        return axiosInstance
            .get<boolean>(`/subscription/detachedcopy/hasDetachedCopies?companyId=${companyId}`)
            .then((response: AxiosResponse<boolean>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        getSubscriptionsForAddCopy,
        getDetachedCopiesForRemove,
        createDetachedCopy,
        deleteDetachedCopy,
        hasDetachedCopies,
    };
};

export default DetachedCopiesService;
