import _ from 'lodash';
import useAuth from './useAuth';
import Excel, { Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import RevisionSubscriptionModel, { isRevisionSubscription } from '../models/RevisionSubscriptionModel';
import { LawTypeEnum } from '../models/LawType';
import { RevisionObjStatusEnum, sortByRevisionStatus } from '../models/RevisionObjStatus';
import CustomLawRevisionModel from '../models/CustomLawRevisionModel';
import { htmlToText } from 'html-to-text';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo.png';
import { sortRevisionQuestionGroups } from '../utils/sorter';
import ExcelColumnWidth, { columnKeys } from '../constants/ExcelColumnWidth';
import ActiveFilterExport from '../models/ActiveFilterExport';
import RevisionMediaAttachmentModel from '../models/RevisionMediaAttachmentModel';

const useExport = () => {
    const { t } = useTranslation();
    const { user, company } = useAuth();

    function concatActiveFilterExport(wb: Workbook, activeFilterExport: ActiveFilterExport): Workbook {
        const brake = '\n';
        const colon = ': ';
        const comma = ', ';
        const indent = '    ';
        const doubleIndent = indent + indent;

        const filterWS = wb.addWorksheet('Filter', {
            views: [{ state: 'frozen', ySplit: 2 }],
        });

        filterWS.columns = [{ header: '', key: '', width: 80 }];

        let filterRowNr = 1;
        const filterInfoCell = filterWS.getCell('A' + filterRowNr);

        filterInfoCell.alignment = { indent: 0, vertical: 'top', horizontal: 'left', wrapText: true };

        filterInfoCell.font = {
            bold: true,
            size: 10,
            italic: true,
        };

        filterInfoCell.value =
            'Nedanför visas de filter som var aktiva under exporttillfället i Lagbevakningen.\nDetta för att enklare förstå varför vissa författningar inte är med i listan.';

        filterRowNr++;
        filterWS.addRow(undefined);

        if (activeFilterExport.lawTypeFilter && activeFilterExport.lawTypeFilter.length > 0) {
            activeFilterExport.lawTypeFilter.forEach(lawTypeFilter => {
                filterRowNr++;
                filterWS.addRow(undefined);
                const lawTypeFilterCell = filterWS.getCell('A' + filterRowNr);
                lawTypeFilterCell.alignment = { indent: 0, vertical: 'top', horizontal: 'left', wrapText: true };
                lawTypeFilterCell.value = '';
                lawTypeFilterCell.value += t(LawTypeEnum[lawTypeFilter.name]) + colon + brake;

                lawTypeFilter.lawGroups.forEach((lawGroup, index) => {
                    lawTypeFilterCell.value += indent + lawGroup.lawGroupName;
                    if (index < lawTypeFilter.lawGroups.length - 1) {
                        lawTypeFilterCell.value += comma + brake;
                    }
                });
            });
        }

        if (activeFilterExport.customLawsSelected !== undefined) {
            filterRowNr++;
            filterWS.addRow(undefined);
            const customLawsSelectedCell = filterWS.getCell('A' + filterRowNr);
            customLawsSelectedCell.alignment = { indent: 0, vertical: 'top', horizontal: 'left', wrapText: true };
            customLawsSelectedCell.value = 'Visa eget material' + colon + (activeFilterExport.customLawsSelected ? 'JA' : 'NEJ');
        }

        if (activeFilterExport.lawListGroupFilter && activeFilterExport.lawListGroupFilter.length > 0) {
            filterRowNr++;
            filterWS.addRow(undefined);
            const lawListGroupFilterCell = filterWS.getCell('A' + filterRowNr);
            lawListGroupFilterCell.alignment = { indent: 0, vertical: 'top', horizontal: 'left', wrapText: true };
            lawListGroupFilterCell.value = 'Egna laglistor' + colon + brake;
            activeFilterExport.lawListGroupFilter.forEach((lawListGroupFilter, index1) => {
                lawListGroupFilterCell.value += indent + lawListGroupFilter.name + colon + brake;

                lawListGroupFilter.lawLists.forEach((lawList, index2) => {
                    lawListGroupFilterCell.value += doubleIndent + lawList.name;
                    if (index2 < lawListGroupFilter.lawLists.length - 1) {
                        lawListGroupFilterCell.value += comma + brake;
                    } else if (activeFilterExport.lawListGroupFilter && index1 < activeFilterExport.lawListGroupFilter.length - 1) {
                        lawListGroupFilterCell.value += brake;
                    }
                });
            });
        }

        if (activeFilterExport.selectedKeywords && activeFilterExport.selectedKeywords.length > 0) {
            filterRowNr++;
            filterWS.addRow(undefined);
            const selectedKeywordsCell = filterWS.getCell('A' + filterRowNr);
            selectedKeywordsCell.alignment = { indent: 0, vertical: 'top', horizontal: 'left', wrapText: true };
            selectedKeywordsCell.value = 'Valda nyckeord' + colon + brake;
            activeFilterExport.selectedKeywords.forEach((selectedKeyword, index) => {
                selectedKeywordsCell.value += indent + selectedKeyword;
                if (activeFilterExport.selectedKeywords && index < activeFilterExport.selectedKeywords.length - 1) {
                    selectedKeywordsCell.value += comma + brake;
                }
            });
        }

        if (activeFilterExport.globalSearch.length > 0) {
            filterRowNr++;
            filterWS.addRow(undefined);
            const globalSearchCell = filterWS.getCell('A' + filterRowNr);
            globalSearchCell.alignment = { indent: 0, vertical: 'top', horizontal: 'left', wrapText: true };
            globalSearchCell.value = 'Sökord globalt' + colon + activeFilterExport.globalSearch;
        }

        if (activeFilterExport.columnSearch.length > 0) {
            filterRowNr++;
            filterWS.addRow(undefined);
            const columnSearchCell = filterWS.getCell('A' + filterRowNr);
            columnSearchCell.alignment = { indent: 0, vertical: 'top', horizontal: 'left', wrapText: true };

            columnSearchCell.value = 'Sökord i kolumner' + colon + brake;
            activeFilterExport.columnSearch.forEach((columnSearch, index) => {
                columnSearchCell.value += indent + t(columnSearch.header) + colon + columnSearch.value;
                if (activeFilterExport.columnSearch && index < activeFilterExport.columnSearch.length - 1) {
                    columnSearchCell.value += brake;
                }
            });
        }

        return wb;
    }

    const generateWorkBook = (
        columns: { key: string; Header: string; width?: number }[],
        data: any[],
        name: string,
        activeFilterExport?: ActiveFilterExport,
        from?: Date,
        to?: Date,
    ): Workbook => {
        const sanitizedData = data.map(obj =>
            _.mapValues(obj, (val: string) =>
                htmlToText(
                    // A quick fix for a deeper issue. With html-to-text, paragraphs have a blank lines between them
                    // while they don't in our editor and accordions. One blank line therefore becomes three blank lines
                    // in the export.

                    // The quick fix to remove <br> from <p> thus minimising the amount of new lines symbols generated
                    // and then halving the amount of consecutive new line symbols.
                    //
                    // Better to reflect on the editor, dangerously setting inner html and how we should convert to text
                    // holistically as our presumption about paragraphs and line breaks are not like common practice.
                    val?.replace('<p><br></p>', '<p/>'),
                    { wordwrap: false },
                ).replace(/\n\n/g, '\n'),
            ),
        );

        console.log(data[0]);
        console.log(sanitizedData[0]);

        const wb = new Excel.Workbook();
        wb.creator = user?.fullName || '';
        wb.created = new Date();
        wb.modified = new Date();

        const ws = wb.addWorksheet(name, {
            views: [{ state: 'frozen', ySplit: 2 }],
        });

        const titleRow = ws.addRow(undefined);
        titleRow.height = 33;

        const lastColumn = `${(columns.length + 9).toString(36).toUpperCase()}`;

        ws.mergeCells('A1', lastColumn + '1');

        const titleCell = ws.getCell('A1');
        titleCell.font = {
            bold: true,
            size: 10,
        };

        titleCell.alignment = { indent: 20, vertical: 'middle', horizontal: 'left', wrapText: true };

        if (from && to) {
            titleCell.value = `Lagbevakningen, Ramboll Sweden AB\n${name} ${from.toLocaleDateString('sv')} - ${to.toLocaleDateString('sv')}`;
        } else {
            titleCell.value = `Lagbevakningen, Ramboll Sweden AB\n${name} ${new Date().toLocaleDateString('sv')}`;
        }

        if (activeFilterExport) {
            titleCell.value += '. Visar ' + activeFilterExport.filteredLawCount + ` av ` + activeFilterExport.originalLawCount + ' lagar';
        }

        const logoImage = wb.addImage({
            base64: logo,
            extension: 'png',
        });
        ws.addImage(logoImage, {
            tl: { col: 0.25, row: 0.5 },
            ext: { width: 126, height: 26 },
        });

        const headers = _.map(columns, column => column.Header);
        ws.getRow(3).values = headers;
        ws.columns = _.map(columns, column => ({
            key: column.key,
            width: column.width || 30,
        }));

        sanitizedData.forEach((d: any) => {
            ws.addRow(d);
        });

        ws.eachRow((row, rowNr) => {
            if (rowNr > 1) {
                row.eachCell(cell => {
                    cell.alignment = { vertical: 'top', wrapText: true };
                    cell.font = { bold: rowNr === 3 };
                });
            }
        });

        const descriptionRowNr = sanitizedData.length + 5;
        const descriptionCellFirstColumn: string = 'A' + descriptionRowNr;
        const descriptionCellLastColumn: string = lastColumn + descriptionRowNr;
        ws.mergeCells(descriptionCellFirstColumn, descriptionCellLastColumn);
        const descriptionCell = ws.getCell(descriptionCellFirstColumn);
        descriptionCell.font = {
            bold: true,
            size: 10,
            italic: true,
        };
        descriptionCell.alignment = { indent: 0, vertical: 'middle', horizontal: 'left', wrapText: true };

        if (activeFilterExport) {
            if (activeFilterExport.originalLawCount > activeFilterExport.filteredLawCount) {
                descriptionCell.value = 'OBS! Listan är exporterad med ett filterurval. Se mer på fliken filter.';
            } else {
                descriptionCell.value = 'Alla tillgängliga författningar är inkluderade. Listan är ej reducerad med filterurval.';
            }
            concatActiveFilterExport(wb, activeFilterExport);
        }

        return wb;
    };

    function concatRevisionMedia(wb: Workbook, exportMediaData: { subId: number; name: string; mediaAttachments: RevisionMediaAttachmentModel[] }[]): Promise<Workbook> {
        return new Promise((resolve, reject) => {
            const reducedExportMediaData = exportMediaData.filter(obj => {
                return obj.mediaAttachments && obj.mediaAttachments.length > 0;
            });
            if (!reducedExportMediaData || reducedExportMediaData.length == 0) {
                resolve(wb);
                return;
            }

            const mediaWS = wb.addWorksheet('Bilder', {
                views: [{ state: 'frozen', ySplit: 1 }, { showGridLines: false }],
            });

            mediaWS.columns = [
                { header: 'Nedanför visas information till bilden', key: '', width: 80 },
                { header: 'Nedanför visas de bilder som laddats upp under revisionen', key: 'Bilder', width: 60 },
            ];

            let mediaRowNr = 1;
            const mediaInfoCell1 = mediaWS.getCell('A' + mediaRowNr);
            mediaInfoCell1.alignment = { indent: 0, vertical: 'top', horizontal: 'left', wrapText: true };
            mediaInfoCell1.font = {
                bold: true,
                size: 12,
                italic: true,
            };

            const mediaInfoCell2 = mediaWS.getCell('B' + mediaRowNr);
            mediaInfoCell2.alignment = { indent: 0, vertical: 'top', horizontal: 'left', wrapText: true };
            mediaInfoCell2.font = {
                bold: true,
                size: 12,
                italic: true,
            };

            mediaRowNr++;

            for (let i1 = 0; i1 < reducedExportMediaData.length; i1++) {
                const mediaAttachments = reducedExportMediaData[i1].mediaAttachments;
                for (let i2 = 0; i2 < mediaAttachments.length; i2++) {
                    const image = new Image();
                    const thumbnail = mediaAttachments[i2].thumbnail;
                    image.src = 'data:image/png;base64,' + thumbnail;

                    image.onload = () => {
                        const height = image.naturalHeight;
                        const width = image.naturalWidth;

                        const myBase64Image = 'data:image/png;base64,' + thumbnail.toString();
                        const imageId2 = wb.addImage({
                            base64: myBase64Image,
                            extension: 'png',
                        });

                        mediaWS.addImage(imageId2, {
                            tl: { col: 1, row: mediaRowNr },
                            ext: { width: width, height: height },
                        });
                        const imageCell = mediaWS.getCell('B' + mediaRowNr);
                        imageCell.alignment = { indent: 0, vertical: 'top', horizontal: 'left', wrapText: true };

                        let currentTextRow = mediaRowNr + 1;

                        const filterInfoCell1 = mediaWS.getCell('A' + currentTextRow);
                        filterInfoCell1.alignment = { indent: 0, vertical: 'top', horizontal: 'left', wrapText: true };
                        filterInfoCell1.value = 'Författning: ' + reducedExportMediaData[i1].name;
                        currentTextRow++;

                        const filterInfoCell2 = mediaWS.getCell('A' + currentTextRow);
                        filterInfoCell2.alignment = { indent: 0, vertical: 'top', horizontal: 'left', wrapText: true };
                        filterInfoCell2.value = 'Uppladdad av ' + mediaAttachments[i2].addedBy + ' den ' + mediaAttachments[i2].addedAt;
                        currentTextRow++;

                        const filterInfoCell3 = mediaWS.getCell('A' + currentTextRow);
                        filterInfoCell3.alignment = { indent: 0, vertical: 'top', horizontal: 'left', wrapText: true };
                        filterInfoCell3.value = 'Kommentar: ' + (mediaAttachments[i2].comment ? mediaAttachments[i2].comment : '');
                        currentTextRow++;

                        const filterInfoCell4 = mediaWS.getCell('A' + currentTextRow);
                        filterInfoCell4.alignment = { indent: 0, vertical: 'top', horizontal: 'left', wrapText: true };
                        filterInfoCell4.value = 'Uppladdad under ' + (mediaAttachments[i2].stage === 'NOT_FINISHED' ? 'pågående revision' : 'avslutad revision');
                        currentTextRow++;

                        mediaWS.getCell('A' + mediaRowNr).border = {
                            bottom: { style: 'thin', color: { argb: 'FF000000' } },
                        };
                        mediaRowNr = mediaRowNr + 17;
                        if (i1 == reducedExportMediaData.length - 1 && i2 == mediaAttachments.length - 1) {
                            resolve(wb);
                            return;
                        }
                    };
                }
            }
        });
    }

    const generateRevisionWorkBook = async (
        columns: { key: string; Header: string }[],
        data: any[],
        exportMediaData: { subId: number; name: string; mediaAttachments: RevisionMediaAttachmentModel[] }[],
        revisionComment: string,
        statusSummary: { key: string; value: number }[],
        revisionName: string,
        customRevisionString: string,
    ): Promise<Workbook> => {
        const wb = generateWorkBook(columns, data, customRevisionString);
        const ws = wb.getWorksheet(1);

        // Comment
        ws.addRow(undefined);
        ws.addRow({ [columns[0].key]: t('exportRevisionComments') }).font = { bold: true, underline: 'single' };
        ws.addRow({ [columns[0].key]: revisionComment });
        ws.addRow(undefined);

        // Summary
        const row = ws.addRow(undefined);
        const revisionSummaryCell = ws.getCell('A' + row.number);
        revisionSummaryCell.font = { bold: true, underline: 'single' };
        revisionSummaryCell.value = t('exportSummary').toString();

        const revisionNameCell = ws.getCell('B' + row.number);
        revisionNameCell.font = { bold: true, underline: 'none' };
        revisionNameCell.value = revisionName;

        statusSummary.forEach(statSum => {
            ws.addRow({ [columns[0].key]: statSum.key, [columns[1].key]: statSum.value });
        });

        await concatRevisionMedia(wb, exportMediaData);
        return wb;
    };

    const exportList = async (columns: { key: string; Header: any }[], data: any[], name: string, activeFilterExport?: ActiveFilterExport, from?: Date, to?: Date) => {
        const wb = generateWorkBook(columns, data, name, activeFilterExport, from, to);

        const buf = await wb.xlsx.writeBuffer({});
        saveAs(new Blob([buf]), `${name} ${new Date().toLocaleDateString('sv')}.xlsx`);
    };

    const exportUserList = async (columns: { key: columnKeys; Header: any; width: number }[], data: any[], name: string) => {
        const wb = generateWorkBook(columns, data, name);

        const ws = wb.getWorksheet(1);

        // Summary
        ws.addRow(undefined);
        ws.addRow({ [columns[0].key]: t('exportSummary') }).font = { bold: true, underline: 'single' };
        ws.addRow({ [columns[0].key]: t('exportUserCount'), [columns[1].key]: data.length });

        const buf = await wb.xlsx.writeBuffer({});
        saveAs(new Blob([buf]), `${name} ${new Date().toLocaleDateString('sv')}.xlsx`);
    };

    function concatRevisionQuestions(sub: RevisionSubscriptionModel | CustomLawRevisionModel): string {
        let questions = '';
        if (isRevisionSubscription(sub)) {
            const revisionQuestionGroups: { title: string; revisionQuestions: string[] }[] = sortRevisionQuestionGroups(sub);

            const brake = '<br>';
            const colon = ':';
            const punct = '. ';

            let number = 1;
            revisionQuestionGroups.forEach(rqg => {
                if (rqg.title) {
                    questions += '<strong>' + rqg.title + colon + '</strong>' + brake;
                    rqg.revisionQuestions.forEach(question => {
                        questions += number.toString() + punct + question + brake;
                        number++;
                    });
                } else {
                    questions += questions.length > 0 ? brake : '';
                    questions += '<strong>' + t('ownQuestions') + colon + '</strong>' + brake;
                    rqg.revisionQuestions.forEach(question => {
                        questions += number.toString() + punct + question + brake;
                        number++;
                    });
                }
            });
        } else {
        }
        return questions;
    }

    const exportRevisionList = async (
        data: (RevisionSubscriptionModel | CustomLawRevisionModel)[],
        customRevisionString: string,
        revisionComment: string,
        revisionName: string,
    ): Promise<void> => {
        // FIXME Consolidate duplicate code
        const customColumns: { key: columnKeys; Header: string; width: number; revisionName: string }[] = [];
        for (let i = 1; i <= 5; i++) {
            const title = _.get(company, 'customHeaderName' + i);
            if (!_.isEmpty(title)) {
                const customerTextKey = `customerText${i}` as columnKeys;
                customColumns.push({ key: customerTextKey, Header: title, width: ExcelColumnWidth[customerTextKey], revisionName: revisionName });
            }
        }
        const exportColumns: { key: columnKeys; Header: string; width: number }[] = [
            { key: 'lawGroup', Header: t('columnLawGroup'), width: ExcelColumnWidth.lawGroup },
            { key: 'name', Header: t('columnLawName'), width: ExcelColumnWidth.name },
            { key: 'subId', Header: t('columnSubId'), width: ExcelColumnWidth.subId },
            { key: 'description', Header: t('columnDescription'), width: ExcelColumnWidth.description },
            { key: 'text', Header: t('columnText'), width: ExcelColumnWidth.text },
            ...customColumns,
            { key: 'comment', Header: t('columnComment'), width: ExcelColumnWidth.comment },
            { key: 'status', Header: 'Status', width: ExcelColumnWidth.status },
            ...(company?.hasLawLists
                ? [
                      {
                          key: 'lawLists' as columnKeys,
                          Header: t('columnLawLists'),
                          width: ExcelColumnWidth.lawLists,
                      },
                  ]
                : []),
            ...(company?.hasKeyWords
                ? [
                      {
                          key: 'keywords' as columnKeys,
                          Header: t('columnKeywords'),
                          width: ExcelColumnWidth.keywords,
                      },
                  ]
                : []),
            ...(company?.hasRevisionQuestions
                ? [
                      {
                          key: 'revisionQuestions' as columnKeys,
                          Header: t('columnRevisionQuestions'),
                          width: ExcelColumnWidth.revisionQuestions,
                      },
                  ]
                : []),
            { key: 'attachments', Header: 'Antal bilder', width: ExcelColumnWidth.status },
        ];

        const exportData = data
            .sort((a, b) => sortByRevisionStatus(a.revisionStatus, b.revisionStatus))
            .map(obj => ({
                lawGroup: isRevisionSubscription(obj) ? `${t(LawTypeEnum[obj.lawType])}: ${obj.lawGroupName}` : t(LawTypeEnum['CUSTOM_LAW']),
                name: obj.name,
                subId: isRevisionSubscription(obj) ? t('lawSubId') + ` ${obj.subId}` : obj.customLawEndDate ? t('customLawValidUntil') + ` ${obj.customLawEndDate}` : '',
                description: obj.description,
                text: obj.text,
                customerText1: obj.customerText1,
                customerText2: obj.customerText2,
                customerText3: obj.customerText3,
                customerText4: obj.customerText4,
                customerText5: obj.customerText5,
                comment: obj.comments.length ? obj.comments.map(comment => `${comment.userName} ${comment.commentDateWithoutTime}<br>${comment.comment}`).join('<br><br>') : '',
                status: t(RevisionObjStatusEnum[obj.revisionStatus]),
                lawLists: obj.lawLists ? obj.lawLists.map(ll => (ll.lawListGroup ? ll.lawListGroup.name + ': ' : '') + ll.name).join('<br>') : '',
                keyWords: obj.keywordIds ? obj.keywordIds.map(id => company?.keyWords.find(kw => kw.id === id)?.text).join('<br>') : '',
                revisionQuestions: concatRevisionQuestions(obj),
                attachments: obj.mediaAttachments ? obj.mediaAttachments.length.toString() : '0',
            }));

        const exportMediaData: { subId: number; name: string; mediaAttachments: RevisionMediaAttachmentModel[] }[] = data.map(obj => ({
            subId: isRevisionSubscription(obj) ? obj.subscriptionId : obj.customLawId,
            name: obj.name,
            mediaAttachments: obj.mediaAttachments,
        }));
        const statusSummary: { key: string; value: number }[] = [
            { key: t('revisionStatusTitleNoValue'), value: data.filter(obj => obj.revisionStatus === 'NO_VALUE').length },
            {
                key: t('revisionStatusTitleNotAccepted'),
                value: data.filter(obj => obj.revisionStatus === 'NOT_ACCEPTED').length,
            },
            {
                key: t('revisionStatusTitleAccepted'),
                value: data.filter(obj => obj.revisionStatus === 'ACCEPTED').length,
            },
            {
                key: t('revisionStatusTitleNotRelevant'),
                value: data.filter(obj => obj.revisionStatus === 'NOT_RELEVANT').length,
            },
            {
                key: t('revisionStatusTitleObservation'),
                value: data.filter(obj => obj.revisionStatus === 'OBSERVATION').length,
            },
            {
                key: t('revisionStatusTitleAcceptedAfterNotAccepted'),
                value: data.filter(obj => obj.revisionStatus === 'ACCEPTED_AFTER_NOT_ACCEPTED').length,
            },
            {
                key: t('revisionStatusTitleAcceptedAfterObservation'),
                value: data.filter(obj => obj.revisionStatus === 'ACCEPTED_AFTER_OBSERVATION').length,
            },
        ];
        const wb = await generateRevisionWorkBook(exportColumns, exportData, exportMediaData, revisionComment, statusSummary, revisionName, customRevisionString);
        const buf = await wb.xlsx.writeBuffer({});
        saveAs(new Blob([buf]), `${customRevisionString} ${new Date().toLocaleDateString('sv')}.xlsx`);
    };

    return {
        exportList,
        exportUserList,
        exportRevisionList,
    };
};

export default useExport;
