import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { getAuthApi } from '../config/axios.config';
import ApiErrorModel from '../models/ApiErrorModel';
import { LawListContentModel } from './SubscriptionService';
import ChangesPerCompany from '../models/ChangesPerCompany';
import { AssessmentChoice } from './SubscriptionChangeService';
import SubscriptionModel from '../models/SubscriptionModel';
import CustomLawModel from '../models/CustomLawModel';

export interface AcceptSubscriptionModel {
    subscriptionIds: number[];
    userId: number;
    assessmentChoice: AssessmentChoice;
    comment: string;
    delegateUserIds?: number[];
    sendMailToDelegates?: boolean;
    groupCompany?: boolean;
}

const ConsultantSubscriptionChangesService = () => {
    const axiosInstance = getAuthApi();

    function getChanges(companyId: number): Promise<LawListContentModel> {
        return axiosInstance
            .get<LawListContentModel>(`/consultant/subscription/changes?companyId=${companyId}`)
            .then((response: AxiosResponse<LawListContentModel>) => {
                // Here we map empty string in text and customerText fields to null. We do this patch here instead of
                // in the backend because, the proper backend solution would be a migration script that changes all
                // empty strings to null.
                //
                // TODO: Migrate and update these fields in backend and remove this patch

                const isTextField = (key: string): boolean => !!key.match(/text|customerText*/);
                const updateObjVal = (val: any, key: string): any => (isTextField(key) && val === '' ? null : val);

                return {
                    ...response.data,
                    subscriptions: response.data.subscriptions.map(obj => _.mapValues(obj, updateObjVal) as SubscriptionModel),
                    customLaws: response.data.customLaws.map(obj => _.mapValues(obj, updateObjVal) as CustomLawModel),
                };
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getChangesPerCompanyAsConsultant(userId: number): Promise<ChangesPerCompany[]> {
        return axiosInstance
            .get<ChangesPerCompany[]>(`/consultant/subscription/changes/countPerCompany?userId=${userId}`)
            .then((response: AxiosResponse<ChangesPerCompany[]>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getChangesPerCorporationAsConsultant(userId: number): Promise<ChangesPerCompany[]> {
        return axiosInstance
            .get<ChangesPerCompany[]>(`/consultant/subscription/changes/countPerCorporation?userId=${userId}`)
            .then((response: AxiosResponse<ChangesPerCompany[]>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function getGroupCompanyChanges(companyId: number): Promise<LawListContentModel> {
        return axiosInstance
            .get<LawListContentModel>(`/consultant/subscription/changes/corporation?companyId=${companyId}`)
            .then((response: AxiosResponse<LawListContentModel>) => {
                // Here we map empty string in text and customerText fields to null. We do this patch here instead of
                // in the backend because, the proper backend solution would be a migration script that changes all
                // empty strings to null.
                //
                // TODO: Migrate and update these fields in backend and remove this patch

                const isTextField = (key: string): boolean => !!key.match(/text|customerText*/);
                const updateObjVal = (val: any, key: string): any => (isTextField(key) && val === '' ? null : val);

                return {
                    ...response.data,
                    subscriptions: response.data.subscriptions.map(obj => _.mapValues(obj, updateObjVal) as SubscriptionModel),
                    customLaws: response.data.customLaws.map(obj => _.mapValues(obj, updateObjVal) as CustomLawModel),
                };
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function acceptSubscription(acceptSubscriptionModel: AcceptSubscriptionModel): Promise<AxiosResponse> {
        return axiosInstance.post(`/consultant/subscription/changes/accept`, acceptSubscriptionModel).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    function acceptGroupSubscription(acceptSubscriptionModel: AcceptSubscriptionModel): Promise<AxiosResponse> {
        return axiosInstance.post(`/consultant/subscription/changes/accept`, acceptSubscriptionModel).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    function getNumberOfConsultantChangesForCompany(companyId: number): Promise<number> {
        return axiosInstance
            .get<number>(`/consultant/subscription/changes/count?companyId=${companyId}`)
            .then((response: AxiosResponse<number>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        getChanges,
        getChangesPerCompanyAsConsultant,
        getChangesPerCorporationAsConsultant,
        getGroupCompanyChanges,
        acceptSubscription,
        acceptGroupSubscription,
        getNumberOfConsultantChangesForCompany,
    };
};

export default ConsultantSubscriptionChangesService;
