import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from './EditRevision.module.css';
import placeholderIcon from '../../../assets/images/placeholderIcon.svg';
import RevisionTopbar from '../../../components/RevisionTopbar/RevisionTopbar';
import { REVISION_UNFINISHED, REVISION_FINISHED, REVISION_ALTER } from '../../../constants/Routes';
import useEditRevision from '../../../hooks/useEditRevision';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Filterbar from '../../../components/Filterbar/Filterbar';
import ContextMenu from '../../../components/ContextMenu/ContextMenu';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import Button from '../../../components/Button/Button';
import Table from '../../../components/Table/Table';
import EditRevisionFullView from '../../../components/FullView/EditRevisionFullView/EditRevisionFullView';
import RevisionEditor from '../../../components/RevisionEditor/RevisionEditor';
import { RevisionObjStatus } from '../../../models/RevisionObjStatus';
import _ from 'lodash';
import EditView, { trimHtmlString } from '../../../components/EditView/EditView';
import useAuth from '../../../hooks/useAuth';
import { isRevisionSubscription } from '../../../models/RevisionSubscriptionModel';
import RevisionService from '../../../services/RevisionService';
import CloseRevisionConfirmModal from '../../../components/Modal/CloseRevisionConfirmModal/CloseRevisionConfirmModal';
import statusOkIcon from '../../../assets/images/statusOkIcon.svg';
import { getMaxLengthValidations } from '../../../constants/Validation';
import { isSubscription, getConsultantText } from '../../../models/SubscriptionModel';
import { useTranslation } from 'react-i18next';
import EmptyListView from '../../../components/EmptyListView/EmptyListView';
import EditRevisionWarningModal from '../../../components/Modal/EditRevisionWarningModal/EditRevisionWarningModal';

const initialEdit = { key: '', title: '', value: '' };

const EditRevision = () => {
    const { t } = useTranslation();

    const CONTEXT_LINKS = [
        {
            title: t('contextMenuLawLists'),
            icon: placeholderIcon,
            active: true,
        },
    ];

    const history = useHistory();
    const { revisionId } = useParams();
    const [hideFilterbar, setHideFilterbar] = useState(false);
    const { isRevisor, isAboveAnonymous, isAboveSuperUser, company, fetchNumberOfUnfinishedRevisionsForCompany } = useAuth();
    const {
        loading,
        data,
        filteredData,
        revision,
        progressCompleted,
        totalCompleted,
        globalSearch,
        lawListGroupFilter,
        selectedKeywords,
        showColumnSearch,
        customFilterActive,
        lawTypeFilter,
        customLawsSelected,
        columnSearch,
        columns,
        hiddenColumns,
        hasCustomLaws,
        selectedIndex,
        visibleDataCount,
        fetchData,
        handleGlobalSearchChange,
        handleLawListFilterChange,
        handleLawListGroupFilterChange,
        handleSelectedKeywordChange,
        handleColumnSearchToggle,
        handleLawTypeOpen,
        handleLawTypeChecked,
        handleLawGroupChecked,
        handleColumnVisibleChange,
        handleResetAllFilters,
        handleResetLawTypeFilter,
        handleCustomFilterToggle,
        handleSelection,
        handleUpdateSubscriptionRevisionStatus,
        handleSaveEdit,
        handleUpdateRevisionComment,
        handleVisibleDataCountChange,
        handleSelectAllKeywords,
        handleAllLawListChange,
        handleAllLawTypesChecked,
    } = useEditRevision();

    const [editRevisionOpen, setEditRevisionOpen] = useState(false);
    const [editRevisionLeftViewOpen, setEditRevisionLeftViewOpen] = useState(false);
    const [closeRevisionOpen, setCloseRevisionOpen] = useState(false);
    const [editRevisionWarningModal, setEditRevisionWarningModal] = useState<{ open: boolean; edit: any }>({ open: false, edit: undefined });
    const [edit, setEdit] = useState(initialEdit);

    useEffect(() => {
        if (revisionId) {
            fetchData(+revisionId);
        } else {
            history.push(REVISION_UNFINISHED);
        }
    }, [revisionId]);

    // Reset state on select changes
    useEffect(() => {
        if (selectedIndex < 0) {
            setEditRevisionLeftViewOpen(false);
            setEditRevisionOpen(false);
            setCloseRevisionOpen(false);
            setEdit(initialEdit);
        }
    }, [selectedIndex]);

    const handleConfirmRevisionSubscription = (comment: string, status: RevisionObjStatus): void => {
        handleUpdateSubscriptionRevisionStatus(comment, status, () => {
            setEditRevisionLeftViewOpen(false);
            setEditRevisionOpen(false);
            handleSelection(0);
        });
    };

    const handleSubmit = async () => {
        let editedSubscription;
        const selectedObj = filteredData[selectedIndex];
        if (isRevisionSubscription(selectedObj)) {
            editedSubscription = {
                subscriptionId: selectedObj.subscriptionId,
                subscriptionVersion: selectedObj.subscriptionVersion,
                [edit.key]: trimHtmlString(edit.value),
            };
        } else {
            editedSubscription = {
                customLawId: selectedObj.customLawId,
                customLaw: true,
                version: selectedObj.version,
                [edit.key]: trimHtmlString(edit.value),
            };
        }

        await handleSaveEdit(edit.key, editedSubscription);
        setEdit(initialEdit);
        setEditRevisionWarningModal({ open: false, edit: undefined });
        setHideFilterbar(false);
    };

    const handleConfirmCloseRevision = () => {
        if (revisionId) {
            RevisionService()
                .closeRevision(+revisionId)
                .then(() => {
                    fetchNumberOfUnfinishedRevisionsForCompany();
                    history.push(REVISION_FINISHED);
                });
        }
    };

    const handleAlterRevision = (): void => {
        history.push(REVISION_ALTER + `/${revisionId}`);
    };

    return (
        <div className={styled.EditRevision}>
            {loading && <LoadingSpinner />}
            <RevisionTopbar
                title={revision?.name || ''}
                backUrl={revision?.finished ? REVISION_FINISHED : REVISION_UNFINISHED}
                comment={revision?.comment}
                disableComment={revision?.finished || !isAboveAnonymous()}
                onSubmitComment={handleUpdateRevisionComment}
            >
                {revision && (
                    <div className={styled.TopbarChildren}>
                        {!revision?.revisionOk && (
                            <ProgressBar progress={progressCompleted} total={totalCompleted} text={revision.finished ? t('deviationsRemedied') : t('revisedLaws')} stacked />
                        )}
                        {revision?.finished && revision?.revisionOk && (
                            <span className={styled.TopbarStatus}>
                                Revision OK <img src={statusOkIcon} alt="OK" />
                            </span>
                        )}
                        {isRevisor() && !revision?.finished && (
                            <Button variant="Primary" onClick={() => setCloseRevisionOpen(true)}>
                                {t('buttonFinishRevision')}
                            </Button>
                        )}
                    </div>
                )}
            </RevisionTopbar>
            <div className={styled.Content}>
                <ContextMenu
                    visibleDataCount={visibleDataCount}
                    totalDataCount={data.length}
                    links={CONTEXT_LINKS}
                    lawTypeFilter={lawTypeFilter}
                    customLawsSelected={customLawsSelected}
                    hasCustomLaws={hasCustomLaws}
                    onToggleLawTypeOpen={handleLawTypeOpen}
                    onToggleLawGroupChecked={handleLawGroupChecked}
                    onToggleLawTypeChecked={handleLawTypeChecked}
                    onResetAllFilters={handleResetAllFilters}
                    onResetLawTypeFilter={handleResetLawTypeFilter}
                    onToggleAllLawTypesChecked={handleAllLawTypesChecked}
                />
                <div className={styled.List}>
                    {!hideFilterbar && (
                        <Filterbar
                            globalSearch={globalSearch}
                            selectedKeywords={selectedKeywords}
                            lawListGroupFilter={lawListGroupFilter}
                            showColumnSearch={showColumnSearch}
                            customFilterActive={customFilterActive}
                            columns={columns.filter(col => !col.alwaysVisible)}
                            onGlobalSearchChange={handleGlobalSearchChange}
                            onLawListFilterChange={handleLawListFilterChange}
                            onLawListGroupFilterChange={handleLawListGroupFilterChange}
                            onSelectedKeywordChange={handleSelectedKeywordChange}
                            onColumnSearchToggle={handleColumnSearchToggle}
                            onColumnShowChange={handleColumnVisibleChange}
                            onCustomFilterToggle={handleCustomFilterToggle}
                            onAlterRevision={handleAlterRevision}
                            onAllLawListChange={handleAllLawListChange}
                            onSelectAllKeywords={handleSelectAllKeywords}
                        />
                    )}
                    <div className={styled.TableWrapper}>
                        {!loading && data && data.length > 0 && visibleDataCount < 1 ? <EmptyListView emptyFilter /> : null}

                        <Table
                            selected={selectedIndex}
                            columns={columns}
                            data={filteredData}
                            showColumnSearch={showColumnSearch}
                            columnSearch={columnSearch}
                            globalSearch={globalSearch}
                            hiddenColumns={hiddenColumns}
                            onSelect={handleSelection}
                            onVisibleDataCountChange={handleVisibleDataCountChange}
                            onDoubleClickSelect={
                                !revision?.revisionOk && isRevisor()
                                    ? index => {
                                          handleSelection(index);
                                          setEditRevisionOpen(true);
                                          setEditRevisionLeftViewOpen(true);
                                      }
                                    : undefined
                            }
                        />
                        <EditRevisionFullView
                            finished={revision?.finished}
                            revisionOk={revision?.revisionOk}
                            open={selectedIndex > -1}
                            data={filteredData[selectedIndex]}
                            editRevisionLeftViewOpen={editRevisionLeftViewOpen}
                            onFooterAction={() => {
                                setEditRevisionLeftViewOpen(true);
                                setEditRevisionOpen(true);
                                setHideFilterbar(true);
                            }}
                            hideFooter={editRevisionOpen || edit.key.length > 0}
                            onEdit={(key, title) => {
                                setEditRevisionWarningModal({
                                    open: true,
                                    edit: {
                                        key,
                                        title,
                                        value: _.get(filteredData[selectedIndex], key),
                                    },
                                });
                                setHideFilterbar(true);
                            }}
                            onClose={() => {
                                setEdit(initialEdit);
                                setEditRevisionLeftViewOpen(false);
                                setEditRevisionOpen(false);
                                setHideFilterbar(false);
                                handleSelection(-1);
                            }}
                        />
                        {editRevisionOpen && selectedIndex > -1 && (
                            <RevisionEditor
                                finished={revision?.finished}
                                initialComment={filteredData[selectedIndex].comments[0]}
                                initialStatus={filteredData[selectedIndex].revisionStatus}
                                selectedSub={filteredData[selectedIndex]}
                                editRevisionOpen
                                onCancel={() => {
                                    setEditRevisionLeftViewOpen(false);
                                    setEditRevisionOpen(false);
                                    setHideFilterbar(false);
                                }}
                                onConfirm={handleConfirmRevisionSubscription}
                                loading={loading}
                            />
                        )}
                        {edit.key && selectedIndex > -1 ? (
                            <EditView
                                title={edit.title}
                                value={edit.value}
                                field={edit.key}
                                consultantText={isAboveSuperUser() ? getConsultantText(edit.key, filteredData[selectedIndex]) : undefined}
                                maxLength={getMaxLengthValidations(isSubscription(filteredData[selectedIndex]), edit.key)}
                                onSubmit={handleSubmit}
                                onChange={value => setEdit(e => ({ ...e, value }))}
                                onClose={() => {
                                    setEdit(initialEdit);
                                    setEditRevisionWarningModal({ open: false, edit: undefined });
                                    setHideFilterbar(false);
                                }}
                            />
                        ) : null}
                    </div>

                    {closeRevisionOpen && revision && (
                        <CloseRevisionConfirmModal
                            name={revision.name}
                            data={data}
                            responsibleName={revision.responsibleName}
                            comment={revision.comment}
                            onUpdateComment={handleUpdateRevisionComment}
                            onCancel={() => setCloseRevisionOpen(false)}
                            onConfirm={handleConfirmCloseRevision}
                        />
                    )}

                    {editRevisionWarningModal.open && editRevisionWarningModal.edit && (
                        <EditRevisionWarningModal
                            onCancel={() => {
                                setEditRevisionWarningModal({ open: false, edit: undefined });
                                setHideFilterbar(false);
                            }}
                            onConfirm={() => {
                                setEdit(editRevisionWarningModal.edit);
                                setEditRevisionWarningModal({ open: false, edit: undefined });
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default EditRevision;
