import { AxiosResponse, AxiosError } from 'axios';
import _ from 'lodash';
import { getAuthApi } from '../config/axios.config';
import SubscriptionModel from '../models/SubscriptionModel';
import ApiErrorModel from '../models/ApiErrorModel';
import LawListModel from '../models/LawListModel';
import AcceptanceLogModel from '../models/AcceptanceLogModel';
import LawTypeModel from '../models/LawTypeModel';

interface ChangeListContentModel {
    lawTypes: LawTypeModel[];
    lawLists: LawListModel[];
    subscriptions: SubscriptionModel[];
}

export type AssessmentChoice = 'ACCEPT' | 'DECLINE' | 'FORWARD_NO_CHOICE';

const SubscriptionChangeService = () => {
    const axiosInstance = getAuthApi();

    function getChanges(companyId: number): Promise<ChangeListContentModel> {
        return axiosInstance
            .get<ChangeListContentModel>(`/subscription/changes?companyId=${companyId}`)
            .then((response: AxiosResponse<ChangeListContentModel>) => {
                // Here we map empty string in text and customerText fields to null. We do this patch here instead of
                // in the backend because, the proper backend solution would be a migration script that changes all
                // empty strings to null.
                //
                // TODO: Migrate and update these fields in backend and remove this patch

                const isTextField = (key: string): boolean => !!key.match(/text|customerText*/);
                const updateObjVal = (val: any, key: string): any => (isTextField(key) && val === '' ? null : val);

                return {
                    ...response.data,
                    subscriptions: response.data.subscriptions.map(obj => _.mapValues(obj, updateObjVal) as SubscriptionModel),
                };
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getAcceptanceLog(subscriptionId: number): Promise<AcceptanceLogModel[]> {
        return axiosInstance
            .get<AcceptanceLogModel[]>(`/subscription/changes/acceptancelog?subscriptionId=${subscriptionId}`)
            .then((response: AxiosResponse<AcceptanceLogModel[]>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function acceptSubscription(
        subscriptionId: number,
        userId: number,
        changeHasImpact: boolean,
        comment: string | undefined,
        addedAssessmentUsers?: number[],
        sendMail?: boolean,
    ): Promise<SubscriptionModel> {
        const data = new FormData();
        data.append('subscriptionId', `${subscriptionId}`);
        data.append('userId', `${userId}`);
        data.append('changeHasImpact', `${changeHasImpact}`);
        data.append('comment', comment || '');
        data.append('sendMailToDelegates', `${sendMail}`);
        if (addedAssessmentUsers && addedAssessmentUsers.length > 0) {
            data.append('delegateUserIds', `${addedAssessmentUsers}`);
        }

        return axiosInstance
            .post<SubscriptionModel>('/subscription/changes/accept', data)
            .then((response: AxiosResponse<SubscriptionModel>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function handleAssessmentOnNew(
        subscriptionId: number,
        userId: number,
        assessmentChoice: AssessmentChoice,
        comment: string,
        delegateUserIds: number[],
        sendMailToDelegates: boolean,
    ): Promise<SubscriptionModel> {
        const data = new FormData();
        data.append('subscriptionId', `${subscriptionId}`);
        data.append('userId', `${userId}`);
        data.append('assessmentChoice', `${assessmentChoice}`);
        data.append('comment', comment);
        data.append('delegateUserIds', `${delegateUserIds}`);
        data.append('sendMailToDelegates', `${sendMailToDelegates}`);

        return axiosInstance
            .post<SubscriptionModel>(`/subscription/changes/acceptnew`, data)
            .then((response: AxiosResponse<SubscriptionModel>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function delegateSubscription(
        subscriptionId: number,
        userId: number,
        comment: string | undefined,
        delegateUserIds: number[],
        assessmentChoice: AssessmentChoice,
        sendMailToDelegates: boolean,
    ): Promise<SubscriptionModel> {
        const data = new FormData();
        data.append('subscriptionId', `${subscriptionId}`);
        data.append('userId', `${userId}`);
        data.append('comment', comment || '');
        data.append('delegateUserIds', `${delegateUserIds}`);
        data.append('assessmentChoice', `${assessmentChoice}`);
        data.append('sendMailToDelegates', `${sendMailToDelegates}`);

        return axiosInstance
            .post<SubscriptionModel>(`/subscription/changes/delegate`, data)
            .then((response: AxiosResponse<SubscriptionModel>) => {
                const { data } = response;
                return data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function changeDelegation(
        subscriptionId: number,
        subscriptionVersion: number,
        userId: number,
        comment: string | undefined,
        delegateUserIds: number[],
        sendMail: boolean,
    ): Promise<SubscriptionModel> {
        const data = new FormData();
        data.append('subscriptionId', `${subscriptionId}`);
        data.append('subscriptionVersion', `${subscriptionVersion}`);
        data.append('userId', `${userId}`);
        data.append('comment', comment || '');
        data.append('delegateUserIds', `${delegateUserIds}`);
        data.append('sendMailToDelegates', `${sendMail}`);
        return axiosInstance
            .post<SubscriptionModel>(`/subscription/changes/changedelegate`, data)
            .then((response: AxiosResponse<SubscriptionModel>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getArchive(companyId: number, from: string, to: string): Promise<{ acceptanceLogs: AcceptanceLogModel[]; lawTypes: LawTypeModel[] }> {
        return axiosInstance
            .get<{ lawTypes: LawTypeModel[]; acceptanceLogs: any[] }>(`/subscription/changes/archive?companyId=${companyId}&from=${from}&to=${to}`)
            .then((response: AxiosResponse<{ lawTypes: LawTypeModel[]; acceptanceLogs: any[] }>) => {
                const { lawTypes, acceptanceLogs } = response.data;

                return {
                    acceptanceLogs: acceptanceLogs.map(obj => ({
                        ...obj,
                        name: obj.lawName,
                        subId: obj.lawSubId,
                    })),
                    lawTypes,
                };
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function revertAllChange(lawId: number, companyId: number): Promise<void> {
        return axiosInstance
            .post(`/subscription/changes/revertAll?lawId=${lawId}&companyId=${companyId}`, undefined)
            .then(() => {
                return;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function validateSubmitRevertOwnChange(lawId: number, companyId: number, userId: number): Promise<boolean> {
        return axiosInstance
            .post(`/subscription/changes/validateRevertOwn?lawId=${lawId}&companyId=${companyId}&userId=${userId}`, undefined)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                return error;
            });
    }

    function revertOwnChange(acceptanceLogId: number, lawId: number, companyId: number, userId: number): Promise<void> {
        return axiosInstance
            .post(`/subscription/changes/revertOwn?acceptanceLogId=${acceptanceLogId}&lawId=${lawId}&companyId=${companyId}&userId=${userId}`, undefined)
            .then(() => {
                return;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function getNumberOfChangesForCompany(companyId: number): Promise<number> {
        return axiosInstance
            .get<number>(`/subscription/changes/count?companyId=${companyId}`)
            .then((response: AxiosResponse<number>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateAcceptanceLogComment(companyId: number, acceptanceLogId: number, comment: string): Promise<AcceptanceLogModel> {
        return axiosInstance
            .put<AcceptanceLogModel>(`/subscription/changes/acceptancelog/comment?companyId=${companyId}&acceptanceLogId=${acceptanceLogId}&comment=${encodeURI(comment)}`)
            .then((response: AxiosResponse<AcceptanceLogModel>) => {
                return response.data;
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    return {
        getChanges,
        getAcceptanceLog,
        acceptSubscription,
        handleAssessmentOnNew,
        delegateSubscription,
        changeDelegation,
        getArchive,
        revertAllChange,
        revertOwnChange,
        validateSubmitRevertOwnChange,
        getNumberOfChangesForCompany,
        updateAcceptanceLogComment,
    };
};

export default SubscriptionChangeService;
