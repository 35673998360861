import { AxiosResponse, AxiosError } from 'axios';
import _ from 'lodash';
import { getAuthApi } from '../config/axios.config';
import ApiErrorModel from '../models/ApiErrorModel';
import CustomLawModel, { EditCustomLawModel } from '../models/CustomLawModel';

const CustomLawService = () => {
    const axiosInstance = getAuthApi();

    function getCustomLawList(companyId: number): Promise<CustomLawModel[]> {
        return axiosInstance
            .get<CustomLawModel[]>(`/customlaw/list?companyId=${companyId}`)
            .then((response: AxiosResponse<CustomLawModel[]>) => {
                // Here we map empty string in text and customerText fields to null. We do this patch here instead of
                // in the backend because, the proper backend solution would be a migration script that changes all
                // empty strings to null.
                //
                // TODO: Migrate and update these fields in backend and remove this patch

                const isTextField = (key: string): boolean => !!key.match(/text|customerText*/);
                const updateObjVal = (val: any, key: string): any => (isTextField(key) && val === '' ? null : val);

                return response.data.map(obj => _.mapValues(obj, updateObjVal) as CustomLawModel);
            })
            .catch((error: AxiosError<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateCustomLawTexts(customLaw: CustomLawModel): Promise<CustomLawModel> {
        return axiosInstance
            .post<CustomLawModel>('/customlaw/update', customLaw)
            .then((response: AxiosResponse<CustomLawModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function createCustomLaw(customLaw: EditCustomLawModel, file?: File): Promise<CustomLawModel> {
        const form = new FormData();
        form.append('editCustomLawModel', new Blob([JSON.stringify(customLaw)], { type: 'application/json' }));
        if (file) {
            form.append('file', file);
        }

        return axiosInstance
            .post<CustomLawModel>('/customlaw', form)
            .then((response: AxiosResponse<CustomLawModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function updateCustomLaw(customLaw: EditCustomLawModel, file?: File): Promise<CustomLawModel> {
        const form = new FormData();
        form.append('editCustomLawModel', new Blob([JSON.stringify(customLaw)], { type: 'application/json' }));
        if (file) {
            form.append('file', file);
        }

        return axiosInstance
            .put<CustomLawModel>('/customlaw', form)
            .then((response: AxiosResponse<CustomLawModel>) => {
                return response.data;
            })
            .catch((error: AxiosResponse<ApiErrorModel>) => {
                throw error;
            });
    }

    function removeCustomLaw(customLawId: number): Promise<AxiosResponse<void>> {
        return axiosInstance.delete<void>(`/customlaw?customLawId=${customLawId}`).catch((error: AxiosResponse<ApiErrorModel>) => {
            throw error;
        });
    }

    return {
        getCustomLawList,
        updateCustomLawTexts,
        createCustomLaw,
        updateCustomLaw,
        removeCustomLaw,
    };
};

export default CustomLawService;
