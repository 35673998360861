import { useContext, useMemo } from 'react';
import { LawListContext } from '../contexts/LawListContext';
import useAuth from './useAuth';
import SubscriptionService from '../services/SubscriptionService';
import { getLawMonitorDefaultSorting, LawType, LawTypeEnum } from '../models/LawType';
import _ from 'lodash';
import UserSettings, { getUserSettingsSelectedLawGroupsFallback } from '../models/UserSettings';
import UserService from '../services/UserService';
import useExport from './useExport';
import { Column, SortingRule } from 'react-table';
import LawListModel from '../models/LawListModel';
import LawTypeFilter, { convertToLawTypeFilter } from '../models/LawTypeFilter';
import LawListGroupFilter, { convertToLawListGroupFilter } from '../models/LawListGroupFilter';
import SubscriptionModel, { isSubscription } from '../models/SubscriptionModel';
import CustomLawService from '../services/CustomLawService';
import RevisionQuestion from '../models/RevisionQuestion';
import RevisionQuestionService from '../services/RevisionQuestionService';
import { useTranslation } from 'react-i18next';
import CustomLawModel from '../models/CustomLawModel';
import ExcelColumnWidth, { columnKeys } from '../constants/ExcelColumnWidth';
import { getActiveFilterExportFromState } from '../models/ActiveFilterExport';
import filterExport from '../utils/filterExport';

const useLawList = () => {
    const { state, setState } = useContext(LawListContext);
    const { t } = useTranslation();
    const { company, user, signedInAsUser, getUserSettings, setUserSettings } = useAuth();
    const { exportList } = useExport();
    const { includeSubscriptionInExport } = filterExport();

    const setLoading = (loading: boolean): void => {
        setState(s => ({
            ...s,
            loading,
        }));
    };

    const handleCustomFilterToggle = (updatedUserSettings?: UserSettings) => {
        const userSettings = updatedUserSettings?.id !== undefined ? updatedUserSettings : getUserSettings();

        if (!userSettings?.id) {
            return;
        }

        if (!state.customFilterActive) {
            setState(s => ({
                ...s,
                customFilterActive: true,
                columns: s.columns.map(c => ({
                    ...c,
                    visible: c.alwaysVisible
                        ? true
                        : (userSettings.bffColumnVisible && c.id === 'text') ||
                          (userSettings.descColumnVisible && c.id === 'description') ||
                          (userSettings.latestRevisionDateColumnVisible && c.id === 'latestRevisionDate'),
                })),
                customColumns: s.customColumns.map(cc => ({
                    ...cc,
                    visible:
                        (userSettings.column1Visible && cc.id === 'customerText1') ||
                        (userSettings.column2Visible && cc.id === 'customerText2') ||
                        (userSettings.column3Visible && cc.id === 'customerText3') ||
                        (userSettings.column4Visible && cc.id === 'customerText4') ||
                        (userSettings.column5Visible && cc.id === 'customerText5') ||
                        (userSettings.keywordColumnVisible && cc.id === 'keywords') ||
                        (userSettings.lawListColumnVisible && cc.id === 'lawLists') ||
                        (userSettings.revisionQuestionColumnVisible && cc.id === 'revisionQuestions'),
                })),
                selectedKeywords: s.selectedKeywords.map(kw => ({
                    ...kw,
                    checked: userSettings.selectedKeywords.includes(kw.id),
                })),
                lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                    ...lawType,
                    lawGroups: lawType.lawGroups.map(lawGroup => ({
                        ...lawGroup,
                        checked: getUserSettingsSelectedLawGroupsFallback(userSettings.selectedLawGroups, s.data).includes(lawGroup.lawGroupId),
                    })),
                })),
                customLawsSelected: userSettings.customLawsSelected,
                lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                    ...lawListGroup,
                    lawLists: lawListGroup.lawLists.map(lawList => ({
                        ...lawList,
                        checked: userSettings.selectedLawLists.includes(lawList.lawListId),
                    })),
                })),
            }));
        } else {
            setState(s => ({
                ...s,
                customFilterActive: false,
                columns: s.columns.map(c => ({ ...c, visible: true })),
                customColumns: s.customColumns.map(cc => ({ ...cc, visible: cc.id !== 'revisionQuestions' })),
                selectedKeywords: s.selectedKeywords.map(kw => ({ ...kw, checked: false })),
                lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                    ...lawType,
                    lawGroups: lawType.lawGroups.map(lawGroup => ({
                        ...lawGroup,
                        checked: true,
                    })),
                })),
                lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                    ...lawListGroup,
                    lawLists: lawListGroup.lawLists.map(lawList => ({
                        ...lawList,
                        checked: false,
                    })),
                })),
                customLawsSelected: true,
            }));
        }
    };

    const fetchData = async () => {
        try {
            if (company && user) {
                setLoading(true);
                const { lawTypes, subscriptions, customLaws } = await SubscriptionService().getFullLawListStructure(company.id);
                let userSettings = getUserSettings();
                if (!userSettings?.id) {
                    userSettings = undefined;
                }

                const lawTypeFilter: LawTypeFilter[] = convertToLawTypeFilter(lawTypes, customLaws.length > 0, userSettings);
                const lawListGroupFilter: LawListGroupFilter[] = convertToLawListGroupFilter(company.lawLists, userSettings?.selectedLawLists);

                setState(s => ({
                    ...s,
                    hasCustomLaws: customLaws.length > 0,
                    data: [...subscriptions, ...customLaws],
                    lawTypeFilter,
                    lawListGroupFilter,
                    selectedKeywords: company.keyWords.map(kw => ({
                        ...kw,
                        checked: userSettings ? userSettings.selectedKeywords.includes(kw.id) : false,
                    })),
                    customLawsSelected: userSettings ? userSettings.customLawsSelected : true,
                    loading: false,
                }));

                handleCustomFilterToggle();
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleVisibleDataCountChange = (visibleDataCount: number): void => {
        setState(s => ({
            ...s,
            visibleDataCount,
        }));
    };

    const handleSelectedKeywordChange = (keywordId: number): void => {
        setState(s => ({
            ...s,
            selectedKeywords: s.selectedKeywords.map(keyword => ({
                ...keyword,
                checked: keyword.id === keywordId ? !keyword.checked : keyword.checked,
            })),
            customFilterActive: false,
        }));
    };

    const handleSelectAllKeywords = (checked: boolean): void => {
        setState(s => ({
            ...s,
            selectedKeywords: s.selectedKeywords.map(kw => ({
                ...kw,
                checked,
            })),
            customFilterActive: false,
        }));
    };

    const handleLawTypeOpen = (type: LawType): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                open: lawType.name === type ? !lawType.open : lawType.open,
            })),
        }));
    };

    const handleLawTypeChecked = (type: LawType): void => {
        if (type === 'CUSTOM_LAW') {
            setState(s => ({
                ...s,
                customLawsSelected: !s.customLawsSelected,
                customFilterActive: false,
            }));
        } else {
            setState(s => ({
                ...s,
                lawTypeFilter: s.lawTypeFilter.map(lawType => {
                    if (lawType.name === type) {
                        return {
                            ...lawType,
                            lawGroups: lawType.lawGroups.map(lawGroup => ({
                                ...lawGroup,
                                checked: !lawType.lawGroups.every(l => l.checked),
                            })),
                        };
                    }
                    return lawType;
                }),
                customFilterActive: false,
            }));
        }
    };

    const handleAllLawTypesChecked = (allChecked: boolean): void => {
        setState(s => ({
            ...s,
            customLawsSelected: !allChecked,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: !allChecked,
                })),
            })),
            customFilterActive: false,
        }));
    };

    const handleLawGroupChecked = (lawGroupId: number): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: lawGroup.lawGroupId === lawGroupId ? !lawGroup.checked : lawGroup.checked,
                })),
            })),
            customFilterActive: false,
        }));
    };

    const handleLawListGroupFilterChange = (lawListGroupId: number): void => {
        setState(s => ({
            ...s,
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => {
                if (lawListGroup.lawListGroupId === lawListGroupId) {
                    return {
                        ...lawListGroup,
                        lawLists: lawListGroup.lawLists.map(lawList => ({
                            ...lawList,
                            checked: !lawListGroup.lawLists.every(l => l.checked),
                        })),
                    };
                }
                return lawListGroup;
            }),
            customFilterActive: false,
        }));
    };

    const handleLawListFilterChange = (lawListId: number): void => {
        setState(s => ({
            ...s,
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: lawList.lawListId === lawListId ? !lawList.checked : lawList.checked,
                })),
            })),
            customFilterActive: false,
        }));
    };

    const handleAllLawListChange = (checked: boolean): void => {
        setState(s => ({
            ...s,
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked,
                })),
            })),
            customFilterActive: false,
        }));
    };

    const handleResetLawTypeFilter = (): void => {
        setState(s => ({
            ...s,
            lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                ...lawType,
                lawGroups: lawType.lawGroups.map(lawGroup => ({
                    ...lawGroup,
                    checked: true,
                })),
            })),
            customLawsSelected: true,
            customFilterActive: false,
        }));
    };

    const handleResetAllFilters = () => {
        setState(s => ({
            ...s,
            globalSearch: '',
            selectedKeywords: s.selectedKeywords.map(kw => ({ ...kw, checked: false })),
            columnSearch: s.columnSearch.map(c => ({ ...c, value: undefined })),
            lawListGroupFilter: s.lawListGroupFilter.map(lawListGroup => ({
                ...lawListGroup,
                lawLists: lawListGroup.lawLists.map(lawList => ({
                    ...lawList,
                    checked: false,
                })),
            })),
            customFilterActive: false,
        }));
        handleResetLawTypeFilter();
    };

    const handleColumnSearchToggle = () => {
        setState(s => ({
            ...s,
            columnSearch: s.showColumnSearch ? s.columnSearch.map(c => ({ ...c, value: undefined })) : s.columnSearch,
            showColumnSearch: !s.showColumnSearch,
        }));
    };

    const handleColumnVisibleChange = (id: string): void => {
        setState(s => ({
            ...s,
            columns: s.columns.map(column => ({
                ...column,
                visible: column.id === id ? !column.visible : column.visible,
            })),
            customColumns: s.customColumns.map(column => ({
                ...column,
                visible: column.id === id ? !column.visible : column.visible,
            })),
            customFilterActive: false,
        }));
    };

    const handleGlobalSearchChange = (globalSearch: string): void => {
        setState(s => ({
            ...s,
            globalSearch,
        }));
    };

    const handleColumnSearchChange = (
        columnSearch: {
            id: string;
            value: string | undefined;
        }[],
    ): void => {
        setState(s => ({
            ...s,
            columnSearch,
        }));
    };

    const handleSelection = (selectedIndex: number) => {
        setState(s => ({
            ...s,
            selectedIndex,
        }));
    };

    const handleSaveUserSettings = (): void => {
        const userSettings: UserSettings = {
            id: signedInAsUser?.userSettings?.id || user?.userSettings?.id || undefined,
            descColumnVisible: state.columns.find(c => c.id === 'description')?.visible || false,
            bffColumnVisible: state.columns.find(c => c.id === 'text')?.visible || false,
            dateColumnVisible: user?.userSettings?.dateColumnVisible || false,
            column1Visible: state.customColumns.find(c => c.id === 'customerText1')?.visible || false,
            column2Visible: state.customColumns.find(c => c.id === 'customerText2')?.visible || false,
            column3Visible: state.customColumns.find(c => c.id === 'customerText3')?.visible || false,
            column4Visible: state.customColumns.find(c => c.id === 'customerText4')?.visible || false,
            column5Visible: state.customColumns.find(c => c.id === 'customerText5')?.visible || false,
            keywordColumnVisible: state.customColumns.find(c => c.id === 'keywords')?.visible || false,
            lawListColumnVisible: state.customColumns.find(c => c.id === 'lawLists')?.visible || false,
            delegateColumnVisible: state.customColumns.find(c => c.id === 'delegated')?.visible || false,
            revisionQuestionColumnVisible: state.customColumns.find(c => c.id === 'revisionQuestions')?.visible || false,
            latestRevisionDateColumnVisible: state.columns.find(c => c.id === 'latestRevisionDate')?.visible || false,
            customLawsSelected: state.customLawsSelected,
            selectedKeywords: state.selectedKeywords.filter(kw => kw.checked).map(kw => kw.id),
            selectedLawGroups: state.lawTypeFilter.flatMap(f => f.lawGroups.filter(lawGroup => lawGroup.checked).map(lawGroup => lawGroup.lawGroupId)),
            selectedLawLists: state.lawListGroupFilter.flatMap(lawListGroup => lawListGroup.lawLists.filter(lawList => lawList.checked).map(lawList => lawList.lawListId)),
            useCustomEmailFilter: false,
        };
        const userId = signedInAsUser?.userId || user?.userId || -1;
        UserService()
            .saveUserSettings(userId, userSettings)
            .then(updatedUserSettings => {
                setUserSettings(updatedUserSettings);
                if (!state.customFilterActive) {
                    handleCustomFilterToggle(updatedUserSettings);
                }
            });
    };

    const getExportRevisionQuestions = (obj: SubscriptionModel | CustomLawModel) => {
        let revisionQuestions: string[] = [];
        let i = 1;
        const nl = '<br>';
        if (company && company.hasRevisionQuestions) {
            if (isSubscription(obj)) {
                revisionQuestions = obj.revisionQuestionGroups.flatMap(rqg => {
                    const includedRevisionQuestions = rqg.revisionQuestions.filter(rq => !rq.excluded);
                    if (includedRevisionQuestions.length > 0) {
                        return [rqg.title + ': ' + nl, ...includedRevisionQuestions.map(rq => i++ + '. ' + rq.question + nl), nl];
                    } else {
                        return [];
                    }
                });
            }

            const customRevisionQuestions = obj.customRevisionQuestions.filter(crq => !crq.excluded).map(crq => crq.question);
            if (customRevisionQuestions.length > 0) {
                revisionQuestions = [...revisionQuestions, t('ownQuestions') + ': ' + nl, ...customRevisionQuestions.map(cq => i++ + '. ' + cq + nl)];
            }
        }
        return revisionQuestions.join('');
    };

    const getExportAcceptanceStatus = (obj: SubscriptionModel | CustomLawModel) => {
        if (!isSubscription(obj)) {
            return '';
        }
        if (obj.acceptanceStatus.includes('NEW')) {
            return 'Ny lag att kvittera';
        } else if (obj.acceptanceStatus.includes('NOT_ACCEPTED')) {
            return 'Ändring att kvittera';
        } else {
            return '';
        }
    };

    const handleExport = () => {
        const { filteredData, columns, customColumns, sorting, columnSearch, globalSearch } = state;

        //default columns that always exist
        let exportColumns: Array<{ key: columnKeys; Header: string; width: number }> = [
            { key: 'lawType', Header: t('columnLawGroup'), width: ExcelColumnWidth.lawType },
            { key: 'name', Header: t('columnLawName'), width: ExcelColumnWidth.name },
            { key: 'subId', Header: t('columnSubId'), width: ExcelColumnWidth.subId },
        ];

        //Get all column ids for columns that are visible
        columns.filter(c => c.visible).forEach(c => exportColumns.push({ key: c.id, Header: c.Header, width: ExcelColumnWidth[c.id] }));
        customColumns.filter(c => c.visible).forEach(c => exportColumns.push({ key: c.id, Header: c.Header, width: ExcelColumnWidth[c.id] }));

        //Remove column Law. This is instead replaced by the 3 static columns
        exportColumns = exportColumns.filter(c => c.key !== 'law');

        // Add acceptanceStatus column
        exportColumns.push({ key: 'acceptanceStatus', Header: t('columnStatusChanges'), width: ExcelColumnWidth.acceptanceStatus });

        const sortedFilteredData = getLawMonitorDefaultSorting(filteredData) as (SubscriptionModel | CustomLawModel)[];

        //If new columns are added in table, they need to be added here also for export to work
        const exportData = sortedFilteredData
            .filter(obj => {
                const searchColumnIds = columnSearch.map(cs => cs.id);
                const searchColumns = [...columns, ...customColumns].filter(c => searchColumnIds.includes(c.id));
                // Filter data from columnSearch, globalSearch, lawLists and keywords
                return includeSubscriptionInExport(obj, columnSearch, searchColumns, columns, globalSearch, getExportRevisionQuestions(obj), customColumns);
            })
            .map(obj => ({
                lawType: isSubscription(obj) ? t(LawTypeEnum[obj.lawType]) + ': ' + obj.lawGroupName : t(LawTypeEnum['CUSTOM_LAW']),
                name: obj.name,
                latestRevisionDate: obj.latestRevisionDate,
                subId: isSubscription(obj) && obj.subId ? t('lawSubId') + ` ${obj.subId}` : obj.customLawEndDate ? t('customLawValidUntil') + ` ${obj.customLawEndDate}` : '',
                description: obj.description,
                text: obj.text,
                customerText1: obj.customerText1,
                customerText2: obj.customerText2,
                customerText3: obj.customerText3,
                customerText4: obj.customerText4,
                customerText5: obj.customerText5,
                lawLists: company?.hasLawLists && obj.lawLists ? obj.lawLists.map(ll => (ll.lawListGroup ? ll.lawListGroup.name + ': ' : '') + ll.name).join('<br>') : '',
                keywords: company?.hasKeyWords && obj.keywordIds ? obj.keywordIds.map(id => _.find(company.keyWords, kw => kw.id === id)?.text).join('<br>') : '',
                revisionQuestions: getExportRevisionQuestions(obj),
                acceptanceStatus: getExportAcceptanceStatus(obj),
            }));

        const activeFilterExport = getActiveFilterExportFromState(state, exportData.length);
        exportList(exportColumns, exportData, 'Laglista', activeFilterExport);
    };

    const handleSortingChange = (sorting: SortingRule<object>[]): void => {
        setState(s => ({
            ...s,
            sorting,
        }));
    };

    const handleSaveSubscription = (editKey: string, subscription: any): Promise<undefined> => {
        return new Promise(resolve => {
            const updateSubInStateData = (res: SubscriptionModel | CustomLawModel) => {
                setState(s => ({
                    ...s,
                    data: s.data.map(obj =>
                        (!isSubscription(obj) && obj.customLawId === subscription.customLawId) || (isSubscription(obj) && obj.subscriptionId === subscription.subscriptionId)
                            ? res
                            : obj,
                    ),
                }));
                resolve();
            };

            if (editKey === 'keywordIds') {
                return SubscriptionService()
                    .updateSubscriptionKeywords(subscription.subscriptionId || subscription.customLawId, !!subscription.customLaw, subscription.keywordIds)
                    .then(updateSubInStateData);
            } else if (editKey === 'lawLists') {
                const lawListIds = subscription.lawLists.map((lawList: LawListModel) => lawList.lawListId);
                return SubscriptionService()
                    .updateSubscriptionLawLists(subscription.subscriptionId || subscription.customLawId, !!subscription.customLaw, lawListIds)
                    .then(updateSubInStateData);
            } else {
                if (subscription.customLaw) {
                    return CustomLawService()
                        .updateCustomLawTexts(subscription)
                        .then(updateSubInStateData);
                } else {
                    return SubscriptionService()
                        .updateSubscription(subscription)
                        .then(updateSubInStateData);
                }
            }
        });
    };

    const handleUnsubscribe = (subscriptionId: number): void => {
        SubscriptionService()
            .unsubscribeSubscription([subscriptionId])
            .then(() => {
                setState(s => {
                    const data = s.data.filter(obj => (isSubscription(obj) && obj.subscriptionId === subscriptionId ? false : true));
                    const lawGroupIds = data.map(obj => obj.lawGroupId);
                    return {
                        ...s,
                        data,
                        lawTypeFilter: s.lawTypeFilter.map(lawType => ({
                            ...lawType,
                            lawGroups: lawType.lawGroups.filter(lawGroup => lawGroupIds.includes(lawGroup.lawGroupId)),
                        })),
                    };
                });
            });
    };

    const handleCreateRevisionQuestion = async (subscriptionId: number, revisionQuestion: Partial<RevisionQuestion>): Promise<number> => {
        setLoading(true);
        const createdRevisionQuestion: RevisionQuestion = await RevisionQuestionService().createCustomRevisionQuestion(subscriptionId, revisionQuestion);
        setState(s => ({
            ...s,
            loading: false,
            data: s.data.map(obj =>
                isSubscription(obj) && obj.subscriptionId === subscriptionId
                    ? {
                          ...obj,
                          customRevisionQuestions: [...obj.customRevisionQuestions, createdRevisionQuestion],
                      }
                    : obj,
            ),
        }));
        return createdRevisionQuestion.id;
    };

    const handleCreateCustomLawRevisionQuestion = async (customLawId: number, revisionQuestion: Partial<RevisionQuestion>): Promise<number> => {
        setLoading(true);
        const createdRevisionQuestion: RevisionQuestion = await RevisionQuestionService().createCustomLawCustomRevisionQuestion(customLawId, revisionQuestion);
        setState(s => ({
            ...s,
            loading: false,
            data: s.data.map(obj =>
                !isSubscription(obj) && obj.customLawId === customLawId
                    ? {
                          ...obj,
                          customRevisionQuestions: [...obj.customRevisionQuestions, createdRevisionQuestion],
                      }
                    : obj,
            ),
        }));
        return createdRevisionQuestion.id;
    };

    const handleUpdateRevisionQuestion = async (id: number, revisionQuestion: RevisionQuestion) => {
        try {
            setLoading(true);
            const updatedRevisionQuestion: RevisionQuestion = await RevisionQuestionService().updateCustomRevisionQuestion(revisionQuestion);
            setState(s => ({
                ...s,
                loading: false,
                data: s.data.map(obj =>
                    (isSubscription(obj) && obj.subscriptionId === id) || (!isSubscription(obj) && obj.customLawId === id)
                        ? {
                              ...obj,
                              customRevisionQuestions: obj.customRevisionQuestions.map(crq =>
                                  crq.id === updatedRevisionQuestion.id ? { ...crq, ...updatedRevisionQuestion } : crq,
                              ),
                          }
                        : obj,
                ),
            }));
        } catch (error) {
            setLoading(false);
        }
    };

    const handleRemoveRevisionQuestion = async (id: number, revisionQuestionId: number) => {
        try {
            setLoading(true);
            await RevisionQuestionService().removeCustomRevisionQuestion(revisionQuestionId);
            setState(s => ({
                ...s,
                loading: false,
                data: s.data.map(obj =>
                    (isSubscription(obj) && obj.subscriptionId === id) || (!isSubscription(obj) && obj.customLawId === id)
                        ? {
                              ...obj,
                              customRevisionQuestions: obj.customRevisionQuestions.filter(crq => crq.id !== revisionQuestionId),
                          }
                        : obj,
                ),
            }));
        } catch (error) {
            setLoading(false);
        }
    };

    const setEditRevisionQuestionsOpen = (editRevisionQuestionsOpen: boolean): void => {
        setState(s => ({
            ...s,
            editRevisionQuestionsOpen,
        }));
    };

    const handleSubmitRevisionQuestionExclusions = async (excludedRevisionQuestionIds: number[], excludedCustomRevisionQuestionIds: number[]) => {
        const selected = state.filteredData[state.selectedIndex];
        if (selected) {
            try {
                setLoading(true);
                const customRevisionQuestions = await RevisionQuestionService().updateCustomRevisionQuestions(
                    selected.customRevisionQuestions.map(crq => ({ ...crq, excluded: excludedCustomRevisionQuestionIds.includes(crq.id) })),
                );
                if (isSubscription(selected)) {
                    const subscriptionModel = await RevisionQuestionService().updateExcludedRevisionQuestions(selected.subscriptionId, excludedRevisionQuestionIds);
                    setState(s => ({
                        ...s,
                        data: s.data.map(obj =>
                            isSubscription(obj) && obj.subscriptionId === selected.subscriptionId
                                ? {
                                      ...obj,
                                      excludedIds: subscriptionModel.excludedBaseRevisionQuestionIds,
                                      subscriptionVersion: subscriptionModel.subscriptionVersion,
                                      customRevisionQuestions,
                                      revisionQuestionGroups: obj.revisionQuestionGroups.map(rqg => ({
                                          ...rqg,
                                          revisionQuestions: rqg.revisionQuestions.map(rq => ({
                                              ...rq,
                                              changedNotification:
                                                  rq.changedNotification === true ? !subscriptionModel.excludedBaseRevisionQuestionIds.includes(rq.id) : rq.changedNotification,
                                              excluded: subscriptionModel.excludedBaseRevisionQuestionIds.includes(rq.questionBaseId!),
                                          })),
                                      })),
                                  }
                                : obj,
                        ),
                        loading: false,
                        editRevisionQuestionsOpen: false,
                    }));
                } else {
                    setState(s => ({
                        ...s,
                        data: s.data.map(obj => (!isSubscription(obj) && obj.customLawId === selected.customLawId ? { ...obj, customRevisionQuestions } : obj)),
                        loading: false,
                        editRevisionQuestionsOpen: false,
                    }));
                }
            } catch (error) {
                setLoading(false);
            }
        }
    };

    const hiddenColumns: string[] = useMemo(() => [...state.columns, ...state.customColumns].filter(column => !column.visible).map(column => column.id), [
        state.columns,
        state.customColumns,
    ]);

    return {
        ...state,
        totalDataCount: state.data.length,
        hiddenColumns,
        fetchData,
        handleSelection,
        handleVisibleDataCountChange,
        handleSelectedKeywordChange,
        handleLawTypeOpen,
        handleLawTypeChecked,
        handleLawGroupChecked,
        handleResetLawTypeFilter,
        handleResetAllFilters,
        handleColumnSearchToggle,
        handleLawListGroupFilterChange,
        handleLawListFilterChange,
        handleCustomFilterToggle,
        handleColumnVisibleChange,
        handleGlobalSearchChange,
        handleSaveUserSettings,
        handleSortingChange,
        handleExport,
        handleSaveSubscription,
        handleUnsubscribe,
        handleCreateRevisionQuestion,
        handleUpdateRevisionQuestion,
        handleRemoveRevisionQuestion,
        handleCreateCustomLawRevisionQuestion,
        setLoading,
        setEditRevisionQuestionsOpen,
        handleSubmitRevisionQuestionExclusions,
        handleSelectAllKeywords,
        handleAllLawListChange,
        handleAllLawTypesChecked,
        handleColumnSearchChange,
    };
};

export default useLawList;
